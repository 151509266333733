<template>
  <div class="app__login">
    <div class="app__login--clever"></div>
    <div class="app__login--form">
      <div style="position: relative; min-width: 400px">
        <div id="logo"></div>
        <auth-form></auth-form>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import AuthForm from "@/components/layout/auth-form";

export default {
  name: "Auth",
  components: { AuthForm }
};
</script>
